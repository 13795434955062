import {
  AddChurchInfoFormType,
  CreateAdminAccountFormType,
} from "../app-schemas/new_church_registration.interface";
import { getCurrentDateFormatted } from "../util";
import { makeRequestWithoutToken } from "./common/services.config";

class NewChurchAuth {
  // create a new church admin account first
  async CreateAdminAccount(data: CreateAdminAccountFormType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append("email", data.email);
      formdata.append("full_name", data.fullName);
      formdata.append("phone_number", data.phone);
      formdata.append("creator_role", data.creatorRole);
      formdata.append("account_type", data.accountType);
      formdata.append("password", data.password);

      const response = await makeRequestWithoutToken.post(
        "organization/create_organization_admin/",
        formdata
      );

      return response;
    } catch (error) {
      return error;
    }
  }

  // add church information to the admin account created
  async AddChurchInfo(data: AddChurchInfoFormType, uuid: string): Promise<any> {
    try {
      let formdata = new FormData();

      formdata.append("short_name", data.shortLink);
      formdata.append("organization_name", data.churchName);
      formdata.append("description", "");
      formdata.append("date_founded", getCurrentDateFormatted());
      formdata.append("website_address", data.website);
      formdata.append("total_members_range", data.totalMembers);
      formdata.append("legal_representative_name", "Church Legal Person");
      formdata.append("tax_id", "Church Tax Id");
      formdata.append("church_address", data.churchAddress);
      formdata.append("city", data.churchCity);
      formdata.append("country", data.churchCountry);

      const response = await makeRequestWithoutToken.post(
        `organization/create_organization/${uuid}/`,
        formdata
      );

      return response;
    } catch (error) {
      return error;
    }
  }

  // check for short name availability
  async CheckShortNameAvailability(shortName: string): Promise<any> {
    try {
      const response = await makeRequestWithoutToken.get(
        `organization/check_short_name/?short_name=${shortName}`
      );

      return response;
    } catch (error) {
      return error;
    }
  }
}

const church = new NewChurchAuth();
export default church;
