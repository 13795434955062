import { LuInfo } from "react-icons/lu";

const AnErrorOccured = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <div className="flex text-red-600 text-[150px] mb-4 justify-center">
          <LuInfo />
        </div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          An Error Occurred
        </h2>
        <p className="text-gray-600 mb-4 text-sm">
          We encountered an unexpected error. Please try again later.
        </p>
        <button
          onClick={() => window.location.reload()}
          className="bg-red-600 text-white px-4 py-2 mt-4 text-sm rounded hover:bg-red-700 transition"
        >
          Reload Page
        </button>
      </div>
    </div>
  );
};

export default AnErrorOccured;
