import { Link } from "react-router-dom";
import { destroySession } from "../../../util";

const HasLoggedIn = () => {
  return (
    <div>
      {/* hero-text area */}
      <div className="flex flex-col items-center gap-2.5 p-3 mt-20">
        <h4 className="text-[3rem] font-[800] text-[#181e35]">You're here!</h4>
        <p className="text-md not-italic font-[500] leading-6 text-[#181e35]">
          You stil have an active session - please hit continue or logout.
        </p>
      </div>

      {/* social-media login */}
      <div className="flex gap-4 mt-12">
        <button
          onClick={() => destroySession()}
          className="bg-red-700 hover:bg-red-600 gap-2 shadow-lg rounded-md border border-2 px-4 py-3 text-white text-xs w-1/2 hover:shadow-xl text-center"
        >
          <span>Logout</span>
        </button>

        <Link
          to="/"
          className="bg-blue-900 hover:bg-blue-800 gap-2 shadow-lg rounded-md border border-2 px-4 py-3 text-white text-xs w-1/2 hover:shadow-xl text-center"
        >
          <span>Continue</span>
        </Link>
      </div>
    </div>
  );
};

export default HasLoggedIn;
