import { z } from "zod";

// common
export interface MembersLoginResponse {
  access_token: string;
  account_type: string;
  associations: any[];
  departments: any[];
  email: string;
  full_name: string;
  is_2fa: boolean;
  phone_number: string;
  refresh_token: string;
  roles: any[];
  user_uuid: string;
}



/**
 *[page 1] users-login
 */
export type MembersLoginType = {
  email: string;
  password: string;
};
export const MembersLoginDefault: MembersLoginType = {
  email: "",
  password: "",
};
export const MembersLoginSchema = z.object({
  email: z.string().email("Valid email address is required"),
  password: z.string().min(1, "Password is required"),
});

/**
 *[page 2] users-register-api
 */
export type MembersSignUpType = {
  full_name: string;
  email: string;
  phone: string;
  password: string;
  confirm_password: string;
};
export const MembersSignUpDefault: MembersSignUpType = {
  full_name: "",
  email: "",
  phone: "",
  password: "",
  confirm_password: "",
};
export const MembersSignUpSchema = z
  .object({
    full_name: z.string().min(3, "Full name is required"),
    email: z.string().email("Invalid email address"),
    phone: z.string().min(6, "Phone number is required"),
    password: z.string().min(6, "Password must be at least 6 characters long"),
    confirm_password: z
      .string()
      .min(6, "Confirm Password must be at least 6 characters long"),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: "Passwords & confirm password must match",
    path: ["confirm_password"],
  });

/**
 *[page 3] users-request-reset
 */
export type MembersRequestResetType = {
  email: string;
};
export const MembersRequestResetDefault: MembersRequestResetType = {
  email: "",
};
export const MembersRequestResetSchema = z.object({
  email: z.string().email("Valid email address is required"),
});

/**
 *[page 4] users-reset-password
 */
export type MembersResetPasswordType = {
  reset_token: string;
  new_password: string;
  confirm_password: string;
};
export const MembersResetPasswordDefault: MembersResetPasswordType = {
  reset_token: "",
  new_password: "",
  confirm_password: "",
};
export const MembersResetPasswordSchema = z
  .object({
    reset_token: z.string().min(6, "Phone number is required"),
    new_password: z
      .string()
      .min(6, "Password must be at least 6 characters long"),
    confirm_password: z
      .string()
      .min(6, "Confirm Password must be at least 6 characters long"),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: "Passwords & confirm password must match",
    path: ["confirm_password"],
  });

/**
 *[page 5] users_two-factor-auth
 */
export type Members2FAType = {
  verification_code: string;
  uuid: string;
};
export const Members2FADefault: Members2FAType = {
  verification_code: "",
  uuid: "",
};
export const Members2FASchema = z.object({
  verification_code: z.string().min(6, "Code must be at least 6 characters long"),
  uuid: z.string().min(25, ""),
});



