//! app wrapper for dynamic-title
import { LayoutProps } from "../../app-schemas/common";
import { Head } from "../../util";

const GlobalLayout = ({ children, title = "GenesisCHMS | Welcome" }: LayoutProps) => (
  <>
    <Head title={title} />
    {children}
  </>
);

export default GlobalLayout;
