import GlobalLayout from "./common/GlobalLayout";
import Header from "./common/Header";
import SideNav from "./common/SideNav";
import { LayoutProps } from "../app-schemas/common";

const MainLayout = ({ children, title = "GenesisCHMS" }: LayoutProps) => (
  <GlobalLayout title={title}>
    <Header />

    <main className="flex">
      <SideNav />

      <section className="main-container bg-gray-100 mt-[60px] ml-[230px] p-5">
        {children}
      </section>
    </main>
  </GlobalLayout>
);

export default MainLayout;
