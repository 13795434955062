import {
  Members2FAType,
  MembersLoginType,
  MembersRequestResetType,
  MembersResetPasswordType,
  MembersSignUpType,
} from "../app-schemas/church_users_authentication.interface";
import { makeRequestWithoutToken } from "./common/services.config";

class ChurchUsersAuth {
  // --- LOGIN SERVICES
  // tenent-user member login via email
  async MembersEmailLogin(data: MembersLoginType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append("username_or_email", data.email);
      formdata.append("password", data.password);

      const response = await makeRequestWithoutToken.post(
        "users/login/",
        formdata
      );

      return response;
    } catch (error) {
      return error;
    }
  }

  // tenent-user member login via Google
  async MembersGooglelLogin(data: MembersLoginType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append("username_or_email", data.email);
      formdata.append("password", data.password);

      const response = await makeRequestWithoutToken.post(
        "users/login/",
        formdata
      );

      return response;
    } catch (error) {
      return error;
    }
  }

  // tenent-user member login via Facebook
  async MembersFacebooklLogin(data: MembersLoginType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append("username_or_email", data.email);
      formdata.append("password", data.password);

      const response = await makeRequestWithoutToken.post(
        "users/login/",
        formdata
      );

      return response;
    } catch (error) {
      return error;
    }
  }

  // --- REGISTER SERVICES
  // add new mwmber via email signup
  async MembersSignUp(data: MembersSignUpType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append("full_name", data.full_name);
      formdata.append("username", data.email);
      formdata.append("email", data.email);
      formdata.append("password", data.password);
      formdata.append("phone", data.phone);

      const response = await makeRequestWithoutToken.post(
        "users/register/",
        formdata
      );

      return response;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  // users-request-reset
  async MembersRequestReset(data: MembersRequestResetType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append("email", data.email);

      const response = await makeRequestWithoutToken.post(
        "users/request-reset/",
        formdata
      );

      return response;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  // users-reset-password
  async MembersUpdatePassword(data: MembersResetPasswordType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append("reset_token", data.reset_token);
      formdata.append("new_password", data.new_password);

      const response = await makeRequestWithoutToken.post(
        "users/update-password/",
        formdata
      );

      return response;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  // user_login_with_code
  async MembersLOGIN2FA(data: Members2FAType): Promise<any> {
    try {
      let formdata = new FormData();
      formdata.append("verification_code", data.verification_code);
      formdata.append("uuid", data.uuid);

      const response = await makeRequestWithoutToken.post(
        "users/login-2fa/",
        formdata
      );

      return response;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }
}

const members_auth = new ChurchUsersAuth();
export default members_auth;
