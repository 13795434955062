import React from "react";

type HtmlLinkProps = {
  href?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
};

const HtmlLink: React.FC<HtmlLinkProps> = ({
  href = "#!",
  className = "",
  children,
}) => {
  return (
    <a
      href={href}
      rel="noreferrer"
      className={`text-orange-600 font-semibold underline ${className}`}
      target="_blank"
    >
      {children}
    </a>
  );
};

export default HtmlLink;
