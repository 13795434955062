import { FormEvent, useEffect, useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import usePreventDefault from "../../hooks/PreventDefault";
import ProgressStepper from "./common/ProgressStepper";
import InputField from "../../components/InputField";
import ButtonComponent from "../../components/ButtonComponent";
import { LuChurch, LuGlobe, LuMap, LuMapPin, LuUsers } from "react-icons/lu";
import SelectDropdown from "../../components/SelectDropdown";
import { countries, total_members_options } from "../../store/data";
import {
  AddChurchInfoFormDefault,
  AddChurchInfoFormSchema,
  AddChurchInfoFormType,
} from "../../app-schemas/new_church_registration.interface";
import useFormInput from "../../hooks/useFormInput";
import church from "../../api-services/new_church_registration.services";
import { tinyAlert } from "../../util";
import { useParams } from "react-router-dom";

const AddChurchInfo = () => {
  var param = useParams();
  const __PDef = usePreventDefault();

  const uuid: string = !!param.uuid ? param.uuid : "";
  const [debouncedShortCode, setDebouncedShortCode] = useState("");
  const [shortCodeerror, setShortCodeerror] = useState("");
  const [loading, setLoading] = useState(false);

  // handle input hook
  const [formData, handleChange, errors, setValidationErrors, , resetForm] =
    useFormInput(AddChurchInfoFormDefault, AddChurchInfoFormSchema);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedShortCode(formData.shortLink);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [formData.shortLink]);

  useEffect(() => {
    setShortCodeerror("");
    if (
      debouncedShortCode === formData.shortLink &&
      formData.shortLink.length > 1
    ) {
      handleShortNameAvailability(formData.shortLink);
    }
  }, [debouncedShortCode, formData.shortLink]);

  // check if short_name already taken
  const handleShortNameAvailability = async (shortLink: string) => {
    const response = await church.CheckShortNameAvailability(shortLink);

    if (response?.data?.message) {
      setShortCodeerror("Name not available!");
      tinyAlert("error", "Organization short name already taken");
    } else {
      setShortCodeerror("");
    }
  };

  const handleAddChurchInfo = async (e: FormEvent) => {
    __PDef(e);

    const validations = AddChurchInfoFormSchema.safeParse(formData);

    if (validations.success) {
      if (!shortCodeerror) {
        setLoading(true);
        const response = await church.AddChurchInfo(validations.data, uuid);
        const message = response?.data?.message;

        if (response && message) {
          resetForm();

          //navigate to subscription page;
        } else {
          const error = response?.response?.data?.error?.short_name;
          tinyAlert("error", `${error}`);
        }

        setLoading(false);
      } else {
        tinyAlert("error", `Short name already exist.`);
      }
    } else {
      const newErrors: Partial<Record<keyof AddChurchInfoFormType, string>> =
        {};

      validations.error.errors.forEach((error) => {
        newErrors[error.path[0] as keyof AddChurchInfoFormType] = error.message;
      });

      setValidationErrors(newErrors);
    }
  };

  return (
    <AuthLayout title="GenesisCHMS | Add your church information">
      <div className="w-9/12 flex flex-col gap-5">
        {/* hero-text area */}
        <div className="flex flex-col items-center gap-2.5 p-3">
          <h4 className="text-[2rem] font-[800] text-[#181e35]">
            Organization Info.
          </h4>
          <p className="text-md not-italic font-[500] leading-6 text-[#181e35]">
            Answer a few questions to get started
          </p>
          <p className="text-xs hidden">
            Complete all required (<span className="text-red-600">*</span>)
            fields
          </p>
        </div>

        {/* form-progress-stepper area */}
        <ProgressStepper currentStep={2} />

        {/* complete form area */}
        <form
          onSubmit={handleAddChurchInfo}
          className="grid grid-cols-2 gap-5 mt-5"
        >
          <InputField
            className="col-span-full"
            type="text"
            id="churchName"
            placeholder="e.g Faith Church Christian Home"
            label="Organization Name"
            value={formData.churchName}
            onChange={handleChange}
            required
            icon={LuChurch}
            error={errors?.churchName}
          />

          <InputField
            type="text"
            id="shortLink"
            placeholder="e.g faithchurch"
            label="Organization Short Name"
            value={formData.shortLink}
            onChange={handleChange}
            required
            icon={LuChurch}
            error={!!shortCodeerror ? shortCodeerror : errors?.shortLink}
          />

          <SelectDropdown
            id="totalMembers"
            value={formData.totalMembers}
            onChange={handleChange}
            options={total_members_options}
            placeholder="Total Members"
            label="Total Members"
            required
            icon={LuUsers}
            error={errors?.totalMembers}
          />

          <InputField
            type="text"
            id="website"
            placeholder="e.g https://faithchurch.com"
            label="Website/Facebook Link"
            value={formData.website}
            onChange={handleChange}
            required
            icon={LuGlobe}
            error={errors?.website}
          />

          <InputField
            type="text"
            id="churchAddress"
            placeholder="e.g 3 New Address Street"
            label="Your Address"
            value={formData.churchAddress}
            onChange={handleChange}
            required
            icon={LuMapPin}
            error={errors?.churchAddress}
          />

          <InputField
            type="text"
            id="churchCity"
            placeholder="e.g ikeja City"
            label="Your City"
            value={formData.churchCity}
            onChange={handleChange}
            required
            icon={LuMap}
            error={errors?.churchCity}
          />

          <SelectDropdown
            id="churchCountry"
            value={formData.churchCountry}
            onChange={handleChange}
            options={countries}
            placeholder="Country"
            label="Country"
            required
            icon={LuGlobe}
            error={errors?.churchCountry}
          />

          <ButtonComponent type="submit" disabled={loading} loading={loading}>
            Create Account
          </ButtonComponent>
        </form>
      </div>
    </AuthLayout>
  );
};

export default AddChurchInfo;
