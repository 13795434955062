import React, { useState } from "react";
import { Eye, EyeSlash } from "./Icons";

type PasswordToggleProps = {
  targetId: string;
};

const PasswordToggle: React.FC<PasswordToggleProps> = ({ targetId }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    const passwordInput = document.getElementById(targetId) as HTMLInputElement;
    if (passwordInput) {
      passwordInput.type = isPasswordVisible ? "password" : "text";
    }
  };

  return (
    <>
      <span
        className="absolute top-4 right-4"
        onClick={togglePasswordVisibility}
        style={{ display: isPasswordVisible ? "none" : "inline" }}
      >
        <Eye />
      </span>
      <span
        className="absolute top-4 right-4"
        onClick={togglePasswordVisibility}
        style={{ display: isPasswordVisible ? "inline" : "none" }}
      >
        <EyeSlash />
      </span>
    </>
  );
};

export default PasswordToggle;
