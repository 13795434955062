import {
  LuBell,
  LuChevronDown,
  LuHelpCircle,
  LuSearch,
  LuSettings,
} from "react-icons/lu";
import { Link } from "react-router-dom";

const Header = () => {
  const churchInfo = {
    logo: "/logo.png",
    quote:
      "The design of God is to put the annointing inside a vessel, not inside a bottle.",
    author: "Pastor Wale Olasoji",
    name: "Living Impact Christian Centre",
  };
  const userInfo = {
    name: "Taiwo Adetayo",
    avatar: "/pastor.png",
  };

  return (
    <section>
      <header className="fixed top-0 right-0 z-[9999] bg-white shadow-sm w-full border-b">
        <div className="flex justify-between py-1">
          <div className="h-[55px] w-[230px]">
            <img
              src={churchInfo.logo}
              alt="logo"
              className="mx-auto h-[55px]"
            />
          </div>

          <div className="flex justify-end gap-8 px-8 py-3 text-[#6b788e]">
            <button className="focus-visible:outline-0 text-2xl flex">
              <LuSearch className="hover:text-orange-500" />
            </button>
            <Link to="/admin/notification" className="focus-visible:outline-0 text-2xl flex">
              <LuBell className="hover:text-orange-500" />
            </Link>
            <Link to="/admin/help" className="focus-visible:outline-0 text-2xl flex gap-1 hover:text-orange-500">
              <LuHelpCircle />
              <span className="text-[14px] font-semibold">Help</span>
            </Link>
            <Link to="/admin/settings" className="focus-visible:outline-0 text-2xl flex gap-1 hover:text-orange-500">
              <LuSettings />
              <span className="text-[14px] font-semibold">Settings</span>
            </Link>

            <div className="relative group flex gap-1.5">
              <div className="rounded-full overflow-hidden w-7 h-7 relative bg-slate-800">
                <img alt="avatar" src={userInfo.avatar} />
              </div>
              <span className="text-[14px] font-medium pt-2 cursor-pointer">
                {userInfo.name}
              </span>
              <button className="pt-2">
                <LuChevronDown />
              </button>
              <ul className="w-full text-xs top-[25px] absolute hidden mt-1 space-y-2 bg-white border border-gray-200 py-2 rounded-md shadow-lg w-36 left-0 z-10 group-hover:block">
                <li>
                  <Link to="/" className="px-3 hover:bg-gray-100 block">Memembers Area</Link>
                </li>
                <li>
                  <Link to="/admin/my-account" className="px-3 hover:bg-gray-100 block">My Account</Link>
                </li>
                <li>
                  <Link to="/admin/billing" className="px-3 hover:bg-gray-100 block">Billing</Link>
                </li>
                <li>
                  <Link to="/auth/login" className="border-t px-3 pt-1 hover:bg-gray-100 block">Logout</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </section>
  );
};

export default Header;
