import { ZodError } from "zod";

type ValidationErrors<T> = Partial<Record<keyof T, string>>;

const useValidationErrors = <T>() => {
  const collectValidationErrors = (validationError: ZodError<T>): ValidationErrors<T> => {
    const newErrors: ValidationErrors<T> = {};
    validationError.errors.forEach((error) => {
      newErrors[error.path[0] as keyof T] = error.message;
    });
    return newErrors;
  };

  return collectValidationErrors;
};

export default useValidationErrors;
