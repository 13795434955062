import {Rccg, Licc, Client1, Client2, Client3, Client4, Client5, Client6} from "../../assets";
const Clients = () => {
  return (
    <div className="w-full px-6 flex gap-10 justify-center mt-16 hidden sm:flex">
      <div className="h-20 w-20">
        <img src={Rccg} alt="" />
      </div>
      <div className="h-20 w-20">
        <img src={Licc} alt="" />
      </div>
      <div className="h-20 w-20">
        <img src={Client1} alt="" />
      </div>
      <div className="h-20 w-20">
        <img src={Client2} alt="" />
      </div>
      <div className="h-20 w-20">
        <img src={Client3} alt="" />
      </div>
      <div className="h-20 w-20">
        <img src={Client4} alt="" />
      </div>
      <div className="h-20 w-20">
        <img src={Client5} alt="" />
      </div>
      <div className="h-20 w-20">
        <img src={Client6} alt="" />
      </div>
    </div>
  );
};

export default Clients;
