import { ReactNode, useEffect, useState } from "react";
import church from "../api-services/new_church_registration.services";
import { isSubdomain } from "../util";
import AccountNotFound from "../views/church-users-authentication/AccountNotFound";
import AnErrorOccured from "../views/church-users-authentication/AnErrorOccured";
import LoadingScreen from "../views/church-users-authentication/LoadingScreen";

interface RequireSubdomainProps {
  children: ReactNode;
}

enum CheckHostState {
  LOADING = "LOADING",
  NOT_AVAILABLE = "NOT AVAILABLE",
  ACTIVE = "ACTIVE",
  ERROR = "ERROR",
}

const RequireSubdomain = ({ children }: RequireSubdomainProps) => {
  const [checkHost, setCheckHost] = useState<CheckHostState>(
    CheckHostState.LOADING
  );
  const checkDomain = isSubdomain();
  const url = window.location.href;
  const hostname = new URL(url).hostname;

  useEffect(() => {
    handleShortNameAvailability(hostname);
  }, [hostname]);

  const handleShortNameAvailability = async (hostname: string) => {
    const parts = hostname.split(".");
    if (parts.length > 0) {
      try {
        const response = await church.CheckShortNameAvailability(parts[0]);
        if (response?.data?.message === true) {
          setCheckHost(CheckHostState.ACTIVE);
        } else if (response?.data?.message === false) {
          setCheckHost(CheckHostState.NOT_AVAILABLE);
        } else {
          setCheckHost(CheckHostState.ERROR);
        }
      } catch (error) {
        setCheckHost(CheckHostState.ERROR);
      }
    } else {
      setCheckHost(CheckHostState.NOT_AVAILABLE);
    }
  };

  if (!checkDomain) {
    return <AccountNotFound />;
  }

  switch (checkHost) {
    case CheckHostState.LOADING:
      return <LoadingScreen />;

    case CheckHostState.ACTIVE:
      return <>{children}</>;

    case CheckHostState.NOT_AVAILABLE:
      return <AccountNotFound />;

    case CheckHostState.ERROR:
    default:
      return <AnErrorOccured />;
  }
};

export default RequireSubdomain;
