import { LuCheck } from "react-icons/lu";

const ProgressStepper = ({ currentStep = 0 }) => {
  return (
    <div className="block m-0">
      <ol className="flex items-center justify-between w-full">
        <li className="flex items-center">
          <div className="items-center justify-between w-full text-center">
            <span className="flex items-center justify-center">
              <span
                className={`flex items-center justify-center w-9 h-9 border rounded-full font-bold border-gray-400 ${
                  currentStep > 1
                    ? "bg-green-800 text-white border-green-100"
                    : currentStep === 1 && "bg-gray-200"
                }`}
              >
                {currentStep > 1 ? (
                  <LuCheck className="text-[25px]" />
                ) : (
                  currentStep <= 1 && <span>1</span>
                )}
              </span>
            </span>
            <span
              className={`text-[10px] block mt-1 font-medium ${
                currentStep > 1 && "text-green-700 font-semibold"
              }`}
            >
              Tell Us about yourself
            </span>
          </div>

          {/* <div className="ml-2 mr-2 border"></div> */}
        </li>
        <li>
          <div className="ml-2 mr-2 border w-[200px]"></div>
        </li>
        <li className="flex items-center">
          <div className="items-center justify-between w-full text-center">
            <span className="flex items-center justify-center">
              <span
                className={`flex items-center justify-center w-9 h-9 border rounded-full font-bold border-gray-400 ${
                  currentStep > 2
                    ? "bg-green-800 text-white border-green-100"
                    : currentStep === 2 && "bg-gray-200"
                }`}
              >
                {currentStep > 2 ? (
                  <LuCheck className="text-[25px]" />
                ) : (
                  currentStep <= 2 && <span>2</span>
                )}
              </span>
            </span>{" "}
            <span
              className={`text-[10px] block mt-1 font-medium ${
                currentStep > 2 && "text-green-700 font-semibold"
              }`}
            >
              Organization Information.
            </span>
          </div>
        </li>
        <li className="flex items-center hidden">
          <div className="items-center justify-between w-full text-center">
            <span className="flex items-center justify-center">
              <span
                className={`flex items-center justify-center w-9 h-9 border rounded-full font-bold border-gray-400 ${
                  currentStep > 3
                    ? "bg-green-800 text-white border-green-100"
                    : currentStep === 3 && "bg-gray-200"
                }`}
              >
                {currentStep > 3 ? (
                  <LuCheck className="text-[25px]" />
                ) : (
                  currentStep <= 3 && <span>3</span>
                )}
              </span>
            </span>{" "}
            <span
              className={`text-[10px] block mt-1 font-medium ${
                currentStep > 3 && "text-green-700 font-semibold"
              }`}
            >
              Where do you meet?
            </span>
          </div>
        </li>
      </ol>
    </div>
  );
};
export default ProgressStepper;
