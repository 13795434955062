import HtmlLink from "../../components/HtmlLink";

const Copyright = () => {
  return (
    <div className="w-full text-center my-12 sm:mt-52 px-4">
      <p className="text-xs mb-2">
        GenesisCHMS - Copyright &copy; 2024 all right reserved
      </p>
      <p className="text-[10px]">
        This site is protected by reCAPTCHA and the Google{" "}
        <HtmlLink>Privacy Policy</HtmlLink> and{" "}
        <HtmlLink>Terms of Service</HtmlLink> apply.
      </p>
    </div>
  );
};

export default Copyright;
