import { LuCheck } from "react-icons/lu";

const ChurchRegAside = () => {
  return (
    <div className="w-9/12 flex flex-col gap-5">
      <h1 className="w-12/12 flex font-medium">GENESISCHMS</h1>

      <h2 className="text-2xl text-center font-semibold mt-10">
        Reclaim your time,
        <br />
        refocus on ministry
      </h2>

      <p className="text-center px-5">
        <span>
          Simplify your workflows, streamline communication, and nurture deeper
          connections with Genesis
        </span>
      </p>

      <p className="flex gap-3 mt-12 animate-slow-fade-in">
        <span>
          <LuCheck className="text-orange-600 text-[25px] w-[50px]" />
        </span>
        <span>
          Manage member information, track attendance, and send targeted
          communications with ease.
        </span>
      </p>

      <p className="flex gap-3 animate-slow-fade-in-5s">
        <span>
          <LuCheck className="text-orange-600 text-[25px] w-[50px]" />
        </span>
        <span>
          Foster deeper connections through online giving, event registrations,
          and volunteer coordination.
        </span>
      </p>

      <p className="flex gap-3 animate-slow-fade-in-7s">
        <span>
          <LuCheck className="text-orange-600 text-[25px] w-[50px]" />
        </span>
        <span>
          Streamline accounting, track donations, and generate insightful
          reports.
        </span>
      </p>

      <p className="flex gap-3 animate-slow-fade-in-9s">
        <span>
          <LuCheck className="text-orange-600 text-[25px] w-[50px]" />
        </span>
        <span>
          Automate routine tasks, eliminate paperwork, and gain valuable
          insights to optimize your operations..
        </span>
      </p>
    </div>
  );
};

export default ChurchRegAside;
