import axios, { AxiosResponse } from "axios";
import "react-toastify/dist/ReactToastify.css";
import { SERVER_URL, TENANT_URL } from "./process.config";

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
type ResponseType =
  | "arraybuffer"
  | "blob"
  | "document"
  | "json"
  | "text"
  | "stream";

// axios.defaults.withCredentials = true;

async function request(
  method: HttpMethod,
  endpoint: string,
  data: Record<string, any> = {},
  responseType: ResponseType = "json"
): Promise<AxiosResponse> {
  const headers = {
    "Content-Type": "application/json",
  };

  let constructed_endpoint = "";
  if (endpoint.match("check_short_name")) {
    constructed_endpoint = `${SERVER_URL}${endpoint}`;
  } else {
    constructed_endpoint = `${TENANT_URL ?? SERVER_URL}${endpoint}`;
  }

  return axios({
    method: method,
    withCredentials: false,
    url: constructed_endpoint,
    data: data,
    headers: headers,
    responseType: responseType,
  });
}

export const makeRequestWithoutToken = {
  get: (endpoint: string, responseType: ResponseType = "json") =>
    request("GET", endpoint, {}, responseType),

  post: (
    endpoint: string,
    data: Record<string, any> = {},
    responseType: ResponseType = "json"
  ) => request("POST", endpoint, data, responseType),

  put: (
    endpoint: string,
    data: Record<string, any> = {},
    responseType: ResponseType = "json"
  ) => request("PUT", endpoint, data, responseType),

  delete: (
    endpoint: string,
    data: Record<string, any> = {},
    responseType: ResponseType = "json"
  ) => request("DELETE", endpoint, data, responseType),

  patch: (
    endpoint: string,
    data: Record<string, any> = {},
    responseType: ResponseType = "json"
  ) => request("PATCH", endpoint, data, responseType),
};
