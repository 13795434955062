import {
  LuCalendarHeart,
  LuFlower,
  LuHeartHandshake,
  LuLayoutGrid,
  LuMessagesSquare,
  LuReceipt,
  LuSettings,
  LuTrendingUp,
  LuUsers2,
} from "react-icons/lu";
import { useLocation } from "react-router-dom";
import NavItem from "./NavItem";

const SideNav = () => {
  const location = useLocation();
  const active_path = location.pathname;

  return (
    <nav className="nav-area bg-white border-r border-gray-200 h-screen overflow-hidden">
      {/* side nav - links */}
      <ul className="font-medium my-2">
        <NavItem
          to="/admin/dashboard"
          activePath={active_path}
          paths={["dashboard"]}
          Icon={LuLayoutGrid}
          label="Dashboard"
        />

        <NavItem
          to="/admin/people/all"
          activePath={active_path}
          paths={[
            "/admin/people/",
            "/admin/people/all",
            "/admin/people/pastors",
            "/admin/people/volunteers",
            "/admin/people/groups",
            "/admin/people/contact-people",
            "/admin/people/add-person",
            "/admin/people/view/",
          ]}
          Icon={LuUsers2}
          label="People"
        />

        <NavItem
          to="/admin/planning-for-sermon"
          activePath={active_path}
          paths={["/admin/planning-for-sermon/"]}
          Icon={LuFlower}
          label="Sermon Planning"
        />

        <NavItem
          to="/admin/schedules"
          activePath={active_path}
          paths={["schedules"]}
          Icon={LuCalendarHeart}
          label="Events & Schedule"
        />

        <NavItem
          to="/admin/financials"
          activePath={active_path}
          paths={["financials"]}
          Icon={LuReceipt}
          label="Financials"
        />

        <NavItem
          to="/admin/report-and-analytics"
          activePath={active_path}
          paths={["report-and-analytics"]}
          Icon={LuTrendingUp}
          label="Report & Analytics"
        />

        <NavItem
          to="/admin/follow-up"
          activePath={active_path}
          paths={["follow-up"]}
          Icon={LuHeartHandshake}
          label="Follow Up"
        />

        <NavItem
          to="/admin/communication"
          activePath={active_path}
          paths={["communication"]}
          Icon={LuMessagesSquare}
          label="Communication"
        />
      </ul>

      {/* side nav- footer link */}
      <ul className="font-medium mb-8">
        <NavItem
          to="/admin/communication"
          activePath={active_path}
          paths={["Settings"]}
          Icon={LuSettings}
          label="Settings"
        />
      </ul>
    </nav>
  );
};

export default SideNav;
