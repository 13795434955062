import { LuCheckSquare, LuSquare } from "react-icons/lu";

interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
}) => {
  return (
    <div className="flex gap-2">
      <div
        className={`text-xl flex items-center justify-center rounded cursor-pointer ${
          checked ? "text-green-800 animate-fast-fade-in" : "text-gray-600"
        }`}
        onClick={() => onChange(!checked)}
      >
        {checked ? <LuCheckSquare /> : <LuSquare style={{strokeWidth:'1px'}} />}
      </div>
      {label && <label className="text-sm" onClick={() => onChange(!checked)}>{label}</label>}
    </div>
  );
};
