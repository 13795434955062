import { useNavigate } from "react-router-dom";
const AccountNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-lg text-center">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
          Account Not Found
        </h1>
        <p className="text-gray-600 mb-6">
          The account you are looking for could not be found. Please check the
          address and try again.
        </p>
        <p className="text-gray-600 mb-6">
          If problems persist, contact our support team.
        </p>
        <button
          onClick={() => navigate("/")}
          className="w-2/4 inline-flex items-center justify-center whitespace-nowrap font-bold text-md py-3 px-4 mb-4 bg-orange-500 text-white w-full rounded-md col-span-full shadow-lg font-medium hover:shadow-2xl disabled:bg-gray-300 disabled:text-gray-400 disabled:shadow-sm "
        >
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default AccountNotFound;
