import { FormEvent, useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import usePreventDefault from "../../hooks/PreventDefault";
import ButtonComponent from "../../components/ButtonComponent";
import { Link, useNavigate } from "react-router-dom";
import {
  MembersLoginDefault,
  MembersLoginSchema,
  MembersLoginType,
} from "../../app-schemas/church_users_authentication.interface";
import useFormInput from "../../hooks/useFormInput";
import members_auth from "../../api-services/church_users_authentication..services";
import { tinyAlert } from "../../util";

const TwoFA = () => {
  const navigate = useNavigate();
  const __PDef = usePreventDefault();
  const [loading, setLoading] = useState(false);

  const [formData, , , setValidationErrors] = useFormInput(
    MembersLoginDefault,
    MembersLoginSchema
  );

  // login to account;
  const handleMembersLogin = async (e: FormEvent) => {
    __PDef(e);

    // Validate the form data
    const { success, data, error } = MembersLoginSchema.safeParse(formData);

    // validate form
    if (success) {
      setLoading(true);

      try {
        const response = await members_auth.MembersEmailLogin(data);

        if (!!response?.data?.data) {
          navigate("/auth/auth-2fa");
        } else {
          tinyAlert("error", response.response.data.error);
        }
      } catch (error) {
        console.log("show error message from network", error);
      } finally {
        setLoading(false);
      }
    } else {
      // Collect errors
      const newErrors: Partial<Record<keyof MembersLoginType, string>> = {};
      error.errors.forEach((err) => {
        newErrors[err.path[0] as keyof MembersLoginType] = err.message;
      });

      setValidationErrors(newErrors);
    }
  };

  return (
    <AuthLayout title="GenesisCHMS | Create my free church admin account">
      <div className="w-9/12 flex flex-col gap-5">
        {/* hero-text area */}
        <div className="flex flex-col items-center gap-2.5 p-3">
          <h4 className="text-[5rem] font-[800] text-[#181e35]">OTP</h4>
          <p className="text-md not-italic font-[500] leading-6 text-[#181e35]">
            Wait! let's verify it's you with 2FA
          </p>
        </div>

        {/* complete form area */}
        <form onSubmit={handleMembersLogin} className="mt-8 justify-center">
          <div className="text-center text-xs mb-3 flex w-full justify-center">
            Enter the code sent to your email address
          </div>

          <div className="otp-input-container flex w-full justify-center">
            <input type="text" className="otp-input" maxLength={1} />
            <input type="text" className="otp-input" maxLength={1} />
            <input type="text" className="otp-input" maxLength={1} />
            <input type="text" className="otp-input" maxLength={1} />
            <input type="text" className="otp-input" maxLength={1} />
            <input type="text" className="otp-input" maxLength={1} />
          </div>

          {/* action btn */}
          <div className="flex justify-center">
            <ButtonComponent
              className="mt-6 w-[290px]"
              type="submit"
              disabled={loading}
              loading={loading}
            >
              Verify
            </ButtonComponent>
          </div>
        </form>

        {/* aggrement area with sign-in link */}
        <div>
          <p className="text-center mb-10 text-sm font-[400]">
            Go back to Login?
            <Link
              to="/auth/login"
              className="font-semibold ml-1 text-[#001944]"
            >
              Click here
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default TwoFA;
