//! define all the app routes here
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RequireSubdomain from "./config";
import { isSubdomain } from "../util";

import CreateAdminAccount from "../views/new-church-registration/CreateAdminAccount";
import MembersLandingPage from "../views/church-website/LandingPage";

import PublicRoutes from "./router/PublicRoutes";
import AuthRoutes from "./router/AuthRoutes";
import AdminRoutes from "./router/AdminRoutes";


function APPRoutes() {
  const checkDomain = isSubdomain();

  return (
    <Router>
      <Routes>
        <Route path="/"  element={checkDomain ? (<RequireSubdomain><MembersLandingPage /></RequireSubdomain>) : (<CreateAdminAccount />)} />

        {/* Public domain Routes */}
        <Route path="/*" element={<PublicRoutes />} />

        {/* Routes that require subdomain check */}
        <Route path="/auth/*" element={<RequireSubdomain><AuthRoutes /></RequireSubdomain>} />
        <Route path="/admin/*" element={<RequireSubdomain><AdminRoutes /></RequireSubdomain>} />
      </Routes>
    </Router>
  );
}

export default APPRoutes;
