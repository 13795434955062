import { LuArrowLeft, LuQuote } from "react-icons/lu";
import { Link } from "react-router-dom";

const ChurchMemberAside = () => {
  const churchInfo = {
    logo: "/logo.png",
    quote:
      "The design of God is to put the annointing inside a vessel, not inside a bottle.",
    author: "Pastor Wale Olasoji",
    name: "Living Impact Christian Centre",
  };

  return (
    <div className="w-9/12 gap-5">
      <Link
        to={"/"}
        className="animate-slow-fade-in-5s text-red-600 p-1 font-medium flex inline font-semibold gap-1"
      >
        <LuArrowLeft className="font-600 text-lg top-[1px] relative" />{" "}
        <span className="mr-2 text-sm">Home</span>
      </Link>

      <div className="flex flex-col items-center justify-center w-max h-auto mt-5">
        {/* //! this section is a dynamic logo  */}
        <img src={churchInfo.logo} alt="logo" width={100} />
        {/* //! this section is a dynamic logo  */}

        {/* <span className="uppercase text-[#0C122C] not-italic font-bold leading-none text-xs mt-2">
          {churchInfo.name}
        </span> */}
      </div>

      {/* //! this section is a slider  */}
      <div className="inline-flex flex-col justify-center items-center">
        <div className="text-center text-[#505F79] my-28">
          <h5 className="font-[900] text-[30px] mb-2 text-[#181e35] flex">
            <LuQuote className="text-[50px] mt-[-10px]" />
            {churchInfo.quote}
          </h5>

          <p className="text-sm font-[500] mb-12 mt-5 text-end">
            - {churchInfo.author}
          </p>
        </div>
      </div>
      {/* //! this section is a slider  */}
    </div>
  );
};

export default ChurchMemberAside;
