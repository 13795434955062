import { useState } from "react";
import {
  LuChevronLeft,
  LuChevronRight,
  LuExpand,
  LuFileEdit,
  LuSearch,
  LuSlidersHorizontal,
  LuTrash2,
} from "react-icons/lu";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { Checkbox } from "../../components/CheckboxComponent";
import MainLayout from "../../layouts/MainLayout";

const PeopleList = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const title = null;

  const paths = [
    { label: "Home", href: "/admin" },
    { label: "People", href: "/admin/people" },
    { label: "All", href: "/admin/people/all" },
  ];

  const peoples_filter_links = [
    { to: "/people/All", label: "All", path: "/admin/people/all" },
    { to: "/people/pastors", label: "Pastors", path: "/admin/people/pastors" },
    {
      to: "/people/volunteers",
      label: "Volunteers",
      path: "/admin/people/volunteers",
    },
    { to: "/people/groups", label: "Groups", path: "/admin/people/groups" },
  ];

  const sampleData = [
    {
      fullName: "John Doe",
      email: "johndoe@example.com",
      homeAddress: "123 Main St, Anytown, USA",
      phone: "555-1234",
      category: "Volunteer",
      location: "First Church",
    },
    {
      fullName: "Jane Smith",
      email: "janesmith@example.com",
      homeAddress: "456 Oak St, Sometown, USA",
      phone: "555-5678",
      category: "Pastor",
      location: "Second Church",
    },
    {
      fullName: "Emily Johnson",
      email: "emilyj@example.com",
      homeAddress: "789 Pine St, Yourtown, USA",
      phone: "555-9101",
      category: "Member",
      location: "Third Church",
    },
    {
      fullName: "Michael Brown",
      email: "michaelb@example.com",
      homeAddress: "321 Elm St, Anothertown, USA",
      phone: "555-1122",
      category: "Volunteer",
      location: "Fourth Church",
    },
    {
      fullName: "Sarah Davis",
      email: "sarahd@example.com",
      homeAddress: "654 Maple St, Mytown, USA",
      phone: "555-3344",
      category: "Pastor",
      location: "Fifth Church",
    },
    {
      fullName: "David Wilson",
      email: "davidw@example.com",
      homeAddress: "987 Cedar St, Yourtown, USA",
      phone: "555-5566",
      category: "Member",
      location: "Sixth Church",
    },
    {
      fullName: "Laura Clark",
      email: "laurac@example.com",
      homeAddress: "159 Birch St, Anycity, USA",
      phone: "555-7788",
      category: "Volunteer",
      location: "Seventh Church",
    },
    {
      fullName: "Robert Lewis",
      email: "robertl@example.com",
      homeAddress: "753 Spruce St, Sometown, USA",
      phone: "555-9900",
      category: "Pastor",
      location: "Eighth Church",
    },
    {
      fullName: "Karen Walker",
      email: "karenw@example.com",
      homeAddress: "852 Pine St, Mytown, USA",
      phone: "555-1011",
      category: "Member",
      location: "Ninth Church",
    },
    {
      fullName: "James Hall",
      email: "jamesh@example.com",
      homeAddress: "147 Oak St, Anothertown, USA",
      phone: "555-1213",
      category: "Volunteer",
      location: "Tenth Church",
    },
    {
      fullName: "Patricia Allen",
      email: "patriciaa@example.com",
      homeAddress: "369 Elm St, Yourtown, USA",
      phone: "555-1415",
      category: "Pastor",
      location: "Eleventh Church",
    },
    {
      fullName: "Jennifer Scott",
      email: "jennifers@example.com",
      homeAddress: "258 Maple St, Anycity, USA",
      phone: "555-1617",
      category: "Member",
      location: "Twelfth Church",
    },
    {
      fullName: "Charles King",
      email: "charlesk@example.com",
      homeAddress: "963 Birch St, Sometown, USA",
      phone: "555-1819",
      category: "Volunteer",
      location: "Thirteenth Church",
    },
    {
      fullName: "Mary Wright",
      email: "maryw@example.com",
      homeAddress: "357 Spruce St, Mytown, USA",
      phone: "555-2021",
      category: "Pastor",
      location: "Fourteenth Church",
    },
    {
      fullName: "Christopher Green",
      email: "christopherg@example.com",
      homeAddress: "741 Cedar St, Yourtown, USA",
      phone: "555-2223",
      category: "Member",
      location: "Fifteenth Church",
    },
    {
      fullName: "Linda Baker",
      email: "lindab@example.com",
      homeAddress: "963 Oak St, Anytown, USA",
      phone: "555-2425",
      category: "Volunteer",
      location: "Sixteenth Church",
    },
    {
      fullName: "Daniel Harris",
      email: "danielh@example.com",
      homeAddress: "159 Pine St, Sometown, USA",
      phone: "555-2627",
      category: "Pastor",
      location: "Seventeenth Church",
    },
    {
      fullName: "Susan Martinez",
      email: "susanm@example.com",
      homeAddress: "357 Maple St, Mytown, USA",
      phone: "555-2829",
      category: "Member",
      location: "Eighteenth Church",
    },
    {
      fullName: "Paul Young",
      email: "pauly@example.com",
      homeAddress: "951 Birch St, Anothertown, USA",
      phone: "555-3031",
      category: "Volunteer",
      location: "Nineteenth Church",
    },
    {
      fullName: "Karen Jackson",
      email: "karenj@example.com",
      homeAddress: "159 Cedar St, Yourtown, USA",
      phone: "555-3233",
      category: "Pastor",
      location: "Twentieth Church",
    },
    {
      fullName: "Nancy Allen",
      email: "nancya@example.com",
      homeAddress: "357 Elm St, Anycity, USA",
      phone: "555-3435",
      category: "Member",
      location: "Twenty-First Church",
    },
    {
      fullName: "Edward Robinson",
      email: "edwardr@example.com",
      homeAddress: "753 Pine St, Sometown, USA",
      phone: "555-3637",
      category: "Volunteer",
      location: "Twenty-Second Church",
    },
    {
      fullName: "Dorothy Walker",
      email: "dorothyw@example.com",
      homeAddress: "456 Oak St, Mytown, USA",
      phone: "555-3839",
      category: "Pastor",
      location: "Twenty-Third Church",
    },
    {
      fullName: "Brian Perez",
      email: "brianp@example.com",
      homeAddress: "789 Maple St, Anytown, USA",
      phone: "555-4041",
      category: "Member",
      location: "Twenty-Fourth Church",
    },
    {
      fullName: "Betty Turner",
      email: "bettyt@example.com",
      homeAddress: "159 Birch St, Sometown, USA",
      phone: "555-4243",
      category: "Volunteer",
      location: "Twenty-Fifth Church",
    },
    {
      fullName: "Mark White",
      email: "markw@example.com",
      homeAddress: "951 Spruce St, Mytown, USA",
      phone: "555-4445",
      category: "Pastor",
      location: "Twenty-Sixth Church",
    },
    {
      fullName: "Donna King",
      email: "donnak@example.com",
      homeAddress: "357 Cedar St, Yourtown, USA",
      phone: "555-4647",
      category: "Member",
      location: "Twenty-Seventh Church",
    },
    {
      fullName: "George Lopez",
      email: "georgel@example.com",
      homeAddress: "753 Elm St, Anothertown, USA",
      phone: "555-4849",
      category: "Volunteer",
      location: "Twenty-Eighth Church",
    },
    {
      fullName: "Sandra Hill",
      email: "sandrah@example.com",
      homeAddress: "951 Oak St, Anycity, USA",
      phone: "555-5051",
      category: "Pastor",
      location: "Twenty-Ninth Church",
    },
    {
      fullName: "Steven Scott",
      email: "stevens@example.com",
      homeAddress: "753 Maple St, Sometown, USA",
      phone: "555-5253",
      category: "Member",
      location: "Thirtieth Church",
    },
    {
      fullName: "Barbara Wright",
      email: "barbaraw@example.com",
      homeAddress: "159 Spruce St, Mytown, USA",
      phone: "555-5455",
      category: "Volunteer",
      location: "Thirty-First Church",
    },
    {
      fullName: "Kevin Young",
      email: "keviny@example.com",
      homeAddress: "357 Birch St, Anytown, USA",
      phone: "555-5657",
      category: "Pastor",
      location: "Thirty-Second Church",
    },
    {
      fullName: "Michelle Martinez",
      email: "michellem@example.com",
      homeAddress: "159 Cedar St, Sometown, USA",
      phone: "555-5859",
      category: "Member",
      location: "Thirty-Third Church",
    },
    {
      fullName: "Jason Lee",
      email: "jasonl@example.com",
      homeAddress: "753 Pine St, Mytown, USA",
      phone: "555-6061",
      category: "Volunteer",
      location: "Thirty-Fourth Church",
    },
    {
      fullName: "Helen Harris",
      email: "helenh@example.com",
      homeAddress: "951 Oak St, Yourtown, USA",
      phone: "555-6263",
      category: "Pastor",
      location: "Thirty-Fifth Church",
    },
    {
      fullName: "Karen Walker",
      email: "karenw2@example.com",
      homeAddress: "753 Maple St, Anothertown, USA",
      phone: "555-6465",
      category: "Member",
      location: "Thirty-Sixth Church",
    },
    {
      fullName: "Thomas Lewis",
      email: "thomasl@example.com",
      homeAddress: "159 Spruce St, Anycity, USA",
      phone: "555-6667",
      category: "Volunteer",
      location: "Thirty-Seventh Church",
    },
    {
      fullName: "Lisa White",
      email: "lisaw@example.com",
      homeAddress: "357 Birch St, Sometown, USA",
      phone: "555-6869",
      category: "Pastor",
      location: "Thirty-Eighth Church",
    },
    {
      fullName: "Mark Harris",
      email: "markh@example.com",
      homeAddress: "951 Cedar St, Mytown, USA",
      phone: "555-7071",
      category: "Member",
      location: "Thirty-Ninth Church",
    },
    {
      fullName: "Sarah King",
      email: "sarahk@example.com",
      homeAddress: "159 Elm St, Yourtown, USA",
      phone: "555-7273",
      category: "Volunteer",
      location: "Fortieth Church",
    },
    {
      fullName: "Joseph Lee",
      email: "josephl@example.com",
      homeAddress: "753 Pine St, Anothertown, USA",
      phone: "555-7475",
      category: "Pastor",
      location: "Forty-First Church",
    },
    {
      fullName: "Patricia Harris",
      email: "patriciah@example.com",
      homeAddress: "951 Oak St, Anycity, USA",
      phone: "555-7677",
      category: "Member",
      location: "Forty-Second Church",
    },
    {
      fullName: "Steven Brown",
      email: "stevenb@example.com",
      homeAddress: "159 Maple St, Sometown, USA",
      phone: "555-7879",
      category: "Volunteer",
      location: "Forty-Third Church",
    },
    {
      fullName: "Nancy Scott",
      email: "nancys@example.com",
      homeAddress: "357 Birch St, Mytown, USA",
      phone: "555-8081",
      category: "Pastor",
      location: "Forty-Fourth Church",
    },
    {
      fullName: "Charles Clark",
      email: "charlesc@example.com",
      homeAddress: "951 Spruce St, Yourtown, USA",
      phone: "555-8283",
      category: "Member",
      location: "Forty-Fifth Church",
    },
    {
      fullName: "Dorothy Martinez",
      email: "dorothym@example.com",
      homeAddress: "159 Elm St, Anytown, USA",
      phone: "555-8485",
      category: "Volunteer",
      location: "Forty-Sixth Church",
    },
    {
      fullName: "Daniel Lee",
      email: "daniell@example.com",
      homeAddress: "357 Pine St, Sometown, USA",
      phone: "555-8687",
      category: "Pastor",
      location: "Forty-Seventh Church",
    },
    {
      fullName: "Betty Young",
      email: "bettyy@example.com",
      homeAddress: "951 Maple St, Mytown, USA",
      phone: "555-8889",
      category: "Member",
      location: "Forty-Eighth Church",
    },
    {
      fullName: "James Walker",
      email: "jamesw2@example.com",
      homeAddress: "159 Cedar St, Yourtown, USA",
      phone: "555-9091",
      category: "Volunteer",
      location: "Forty-Ninth Church",
    },
    {
      fullName: "Emily King",
      email: "emilyk@example.com",
      homeAddress: "753 Birch St, Anothertown, USA",
      phone: "555-9293",
      category: "Pastor",
      location: "Fiftieth Church",
    },
  ];

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  return (
    <MainLayout title={title ?? "GenesisCHMS | People"}>
      <div className="flex justify-between items-center mb-2">
        {/* page breadcrumb */}
        <div>
          <h2 className="text-2xl font-bold text-gray-700">People</h2>
          <p className="text-lg font-normal text-gray-500">
            <Breadcrumb paths={paths} />
          </p>
        </div>

        {/* seach section */}
        <div className="flex gap-4 items-center w-2/6">
          <form className="w-full relative">
            <input
              type="text"
              className="border border-gray-300 rounded-md p-2 px-4 pr-12 w-full text-sm"
              placeholder="Search"
            />
            <LuSearch className="text-gray-400 absolute top-[10px] right-[15px]" />
          </form>
          <button className="bg-white border border-gray-300 hover:shadow-lg text-gray-500 hover:text-gray-700 font-medium rounded-md p-2 flex items-center justify-center gap-2 text-sm">
            <LuSlidersHorizontal />
            <span>Filter</span>
          </button>
        </div>
      </div>

      {/* quick filter panel */}
      <div className="relative z-99">
        <div className="flex space-x-3 border-b w-full text-sm">
          {peoples_filter_links.map((link) => (
            <Link
              key={link.to}
              to={link.path}
              className={`${
                currentPath === link.path && "border-b-2 border-orange-500"
              } bg-inherit text-gray-700 font-medium p-3 flex items-center justify-center outline-0`}
            >
              {link.label}
            </Link>
          ))}
        </div>

        {/* open page to create people and conatct */}
        <div className="flex justify-end gap-2 top-[1px] absolute right-[2px]">
          <Link
            to="/admin/people/add-person"
            className="inline-flex items-center justify-center whitespace-nowrap font-bold text-md py-3 px-4 mb-4 bg-orange-500 text-white w-full rounded-md col-span-full shadow-lg font-medium hover:shadow-2xl disabled:bg-gray-300 disabled:text-gray-400 disabled:shadow-sm w-[130px] h-[35px] text-xs bg-gray-500"
          >
            Add Person
          </Link>

          <Link
            to="/admin/people/contact-people"
            className="inline-flex items-center justify-center whitespace-nowrap font-bold text-md py-3 px-4 mb-4 bg-orange-500 text-white w-full rounded-md col-span-full shadow-lg font-medium hover:shadow-2xl disabled:bg-gray-300 disabled:text-gray-400 disabled:shadow-sm w-[130px] h-[35px] text-xs"
          >
            Contact People
          </Link>

          <button
            onClick={toggleFullScreen}
            className="border-2 border-gray-500 inline-flex items-center justify-center whitespace-nowrap font-bold py-3 mb-4 bg-gray-50 text-gray-700 w-full rounded-md shadow-lg font-medium hover:shadow-2xl disabled:bg-gray-300 h-[35px] text-lg"
          >
            <LuExpand />
          </button>
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <div className="flex gap-2">
          <button className="border border-gray-400 py-2 px-4 bg-gray-100 text-gray-700 rounded hover:bg-gray-300 disabled:opacity-50 text-xs font-semibold">
            Mass Manage (0)
          </button>
          <button className="border border-gray-400 py-2 px-4 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 disabled:opacity-50 text-xs font-semibold">
            Export
          </button>
        </div>

        <div className="flex justify-end items-center gap-x-6">
          <select className="border-b-2 border-gray-400 py-1 px-4 bg-gray-50 text-gray-700 hover:bg-gray-100 disabled:opacity-50 text-xs font-semibold">
            <option>20</option>
            <option>50</option>
            <option>100</option>
            <option>500</option>
          </select>

          <div className="text-sm font-medium">
            <span>1-50 of 2000</span>
          </div>

          <div className="flex gap-1">
            <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 disabled:opacity-50">
              <LuChevronLeft />
            </button>
            <button
              // onClick={}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 disabled:opacity-50"
              // disabled={}
            >
              <LuChevronRight />
            </button>
          </div>
        </div>
      </div>

      {/* export; OrderBy; Pagination area */}
      <div className="overflow-x-auto mt-6 shadow">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-100 text-sm text-left">
            <tr>
              <th className="py-2 px-4 border-b">
                <Checkbox checked={isChecked} onChange={setIsChecked} />
              </th>
              <th className="py-2 px-4 border-b font-semibold">Full Name</th>
              <th className="py-2 px-4 border-b font-semibold">Email</th>
              <th className="py-2 px-4 border-b font-semibold">Address</th>
              <th className="py-2 px-4 border-b font-semibold">Phone</th>
              <th className="py-2 px-4 border-b font-semibold">Category</th>
              <th className="py-2 px-4 border-b font-semibold">Location</th>
              <th className="py-2 px-4 border-b font-semibold"></th>
            </tr>
          </thead>
 
          <tbody className="text-xs font-medium text-gray-700">
            {sampleData.map((person, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="py-3 px-4 border-b border-gray-200">
                  <Checkbox checked={isChecked} onChange={setIsChecked} />
                </td>
                <td className="py-3 px-4 border-b border-gray-200">
                  <Link
                    to="/admin/people/view/ebdb8b63-4a31-4515-84b5-405d7e385d0d"
                    className="hover:underline text-blue-900"
                  >
                    {person.fullName}
                  </Link>
                </td>
                <td className="py-3 px-4 border-b border-gray-200">
                  {person.email}
                </td>
                <td className="py-3 px-4 border-b border-gray-200">
                  {person.homeAddress}
                </td>
                <td className="py-3 px-4 border-b border-gray-200">
                  {person.phone}
                </td>
                <td className="py-3 px-4 border-b border-gray-200">
                  {person.category}
                </td>
                <td className="py-3 px-4 border-b border-gray-200">
                  {person.location}
                </td>
                <td className="py-3 px-4 border-b border-gray-200">
                  <div className=" flex gap-4 text-lg">
                    <Link
                      to="/admin/people/edit/ebdb8b63-4a31-4515-84b5-405d7e385d0d"
                      className="hover:underline text-blue-900"
                    >
                      <LuFileEdit className="text-orange-700" />
                    </Link>

                    <LuTrash2 className="text-red-700" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </MainLayout>
  );
};

export default PeopleList;
