import { FormEvent } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import usePreventDefault from "../../hooks/PreventDefault";
import InputField from "../../components/InputField";
import ButtonComponent from "../../components/ButtonComponent";
import { Link } from "react-router-dom";
import { PadLock, UserEdit } from "../../components/Icons";
import { LuMail, LuPhone } from "react-icons/lu";
import {
  MembersSignUpDefault,
  MembersSignUpSchema,
  MembersSignUpType,
} from "../../app-schemas/church_users_authentication.interface";
import useFormInput from "../../hooks/useFormInput";
import members_auth from "../../api-services/church_users_authentication..services";
import useValidationErrors from "../../hooks/useValidationErrors";

const MembersSignUp = () => {
  const __PDef = usePreventDefault();
  const collectValidationErrors = useValidationErrors<MembersSignUpType>();
  const [formData, handleChange, errors, setValidationErrors, handleCustomChange] = useFormInput(MembersSignUpDefault, MembersSignUpSchema);

  
  const handleMembersSignUp = async (e: FormEvent) => {
    __PDef(e);

    const validations = MembersSignUpSchema.safeParse(formData);

    if (validations.success) {
      await members_auth.MembersSignUp(validations.data);

    } else {
      const newErrors = collectValidationErrors(validations.error);
      setValidationErrors(newErrors);
    }
  };

  return (
    <AuthLayout title="GenesisCHMS | Create my free church admin account">
      <div className="w-9/12 flex flex-col gap-5">
        {/* hero-text area */}
        <div className="flex flex-col items-center gap-2.5 p-3">
          <h4 className="text-[3rem] font-[800] text-[#181e35]">Sign Up</h4>
          <p className="text-md not-italic font-[500] leading-6 text-[#181e35]">
            Let's get you started into your Church Account
          </p>
          <p className="text-xs hidden">
            Complete all required (<span className="text-red-600">*</span>)
            fields
          </p>
        </div>

        {/* complete form area */}
        <form
          onSubmit={handleMembersSignUp}
          className="grid grid-cols-2 gap-5 mt-5"
        >
          {/* enter full-name */}
          <InputField
            className="col-span-full"
            type="text"
            id="full_name"
            placeholder="What is your Full Name"
            label="Full Name"
            value={formData.full_name}
            onChange={handleChange}
            required
            icon={UserEdit}
            error={errors?.full_name}
          />

          {/* enter email */}
          <InputField
            type="email"
            id="email"
            placeholder="Enter Email Address"
            label="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
            icon={LuMail}
            error={errors?.email}
          />

          {/* enter phone */}
          <InputField
            type="tel"
            id="phone"
            placeholder="Enter Phone Number"
            label="Phone"
            value={formData.phone}
            onChange={(e) => handleCustomChange("phone", e.target.value)}
            required
            icon={LuPhone}
            error={errors?.phone}
          />

          {/* enter password */}
          <InputField
            type="password"
            id="password"
            placeholder="* * * * * * * *"
            label="password"
            value={formData.password}
            onChange={handleChange}
            required
            icon={PadLock}
            error={errors?.password}
          />

          {/* confirm password */}
          <InputField
            type="password"
            id="confirm_password"
            placeholder="* * * * * * * *"
            label="Confirm password"
            value={formData.confirm_password}
            onChange={handleChange}
            required
            icon={PadLock}
            error={errors?.confirm_password}
          />

          {/* action btn */}
          <ButtonComponent type="submit">
            {" "}
            Create Member's Account{" "}
          </ButtonComponent>
        </form>

        {/* aggrement area with sign-in link */}
        <div>
          <p className="text-[10px] text-center text-gray-500 mb-2">
            By signing up, you're agreeing to our{" "}
            <Link to="/privacy-policy" className="underline font-semibold">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link to="/terms-of-services" className="underline font-semibold">
              Terms of Service
            </Link>
          </p>

          <p className="text-center font-light mb-10">
            Already have an account?
            <Link to="/auth/login" className="font-semibold ml-1 text-[#001944]">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default MembersSignUp;
