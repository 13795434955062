//! define all the app routes here
import { Routes, Route } from "react-router-dom";

// Import views for app-tenant-registration
import CreateAdminAccount from "../../views/new-church-registration/CreateAdminAccount";
import AddChurchInfo from "../../views/new-church-registration/AddChurchInfo";

function PublicRoutes() {
  return (
    <Routes>
      <Route path="create-admin-account" element={<CreateAdminAccount />} />
      <Route path="complete-registration/:uuid" element={<AddChurchInfo />} />
    </Routes>
  );
}

export default PublicRoutes;
