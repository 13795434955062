import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { LuMail } from "react-icons/lu";
import AuthLayout from "../../layouts/AuthLayout";
import usePreventDefault from "../../hooks/PreventDefault";
import InputField from "../../components/InputField";
import ButtonComponent from "../../components/ButtonComponent";
import { PadLock } from "../../components/Icons";
import {
  MembersLoginDefault,
  MembersLoginResponse,
  MembersLoginSchema,
  MembersLoginType,
} from "../../app-schemas/church_users_authentication.interface";
import useFormInput from "../../hooks/useFormInput";
import members_auth from "../../api-services/church_users_authentication..services";
import { Checkbox } from "../../components/CheckboxComponent";
import { getToken, storeToken, storeUser, tinyAlert } from "../../util";
import HasLoggedIn from "./common/hasLoggedInView";

const MembersLogin = () => {
  const navigate = useNavigate();
  const __PDef = usePreventDefault();
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const [formData, handleChange, errors, setValidationErrors] = useFormInput(
    MembersLoginDefault,
    MembersLoginSchema
  );

  useEffect(() => {
    setIsLoggedIn(!!getToken());
  }, []);

  const handleMembersLogin = async (e: FormEvent) => {
    __PDef(e);

    // Validate the form data
    const { success, data, error } = MembersLoginSchema.safeParse(formData);

    if (success) {
      await handleSuccessfulLogin(data);
    } else {
      handleValidationErrors(error);
    }
  };

  const handleSuccessfulLogin = async (data: MembersLoginType) => {
    setLoading(true);

    try {
      const response = await members_auth.MembersEmailLogin(data);

      if (response?.data?.data?.access_token) {
        processLoginSuccess(response.data.data);
      } else {
        tinyAlert("error", response.response.data.error);
      }
    } catch (error) {
      console.log("show error message from network", error);
    } finally {
      setLoading(false);
    }
  };

  const processLoginSuccess = (data: MembersLoginResponse) => {
    storeToken(data.access_token);
    storeUser(data);

    if (data.is_2fa) {
      navigate("/auth/auth-2fa");
    } else {
      const loginRedirectedFromUrl = localStorage.getItem(
        "loginRedirectedFromUrl"
      );
      localStorage.removeItem("loginRedirectedFromUrl");
      navigate(loginRedirectedFromUrl ?? "/");
    }
  };

  const handleValidationErrors = (error: z.ZodError<MembersLoginType>) => {
    const newErrors: Partial<Record<keyof MembersLoginType, string>> = {};
    error.errors.forEach((err) => {
      newErrors[err.path[0] as keyof MembersLoginType] = err.message;
    });

    setValidationErrors(newErrors);
  };

  return (
    <AuthLayout title="GenesisCHMS | Create my free church admin account">
      {!isLoggedIn ? (
        <div className="w-9/12 flex flex-col gap-5">
          {/* hero-text area */}
          <div className="flex flex-col items-center gap-2.5 p-3">
            <h4 className="text-[3rem] font-[800] text-[#181e35]">Welcome!</h4>
            <p className="text-md not-italic font-[500] leading-6 text-[#181e35]">
              We're glad to see you - Let's sign you in
            </p>
          </div>

          {/* complete form area */}
          <form
            onSubmit={handleMembersLogin}
            className="grid grid-cols-2 gap-5 mt-5"
          >
            {/* enter email */}
            <InputField
              className="col-span-full"
              type="email"
              id="email"
              placeholder="Enter Email Address"
              label="Email Address"
              value={formData.email}
              onChange={handleChange}
              required
              icon={LuMail}
              error={errors?.email}
            />

            {/* enter password */}
            <InputField
              className="col-span-full"
              type="password"
              id="password"
              placeholder="* * * * * * * *"
              label="password"
              value={formData.password}
              onChange={handleChange}
              required
              icon={PadLock}
              error={errors?.password}
            />

            <div className="flex justify-between">
              <Checkbox
                label="Remember Me"
                checked={isChecked}
                onChange={setIsChecked}
              />
            </div>

            {/* action btn */}
            <ButtonComponent type="submit" disabled={loading} loading={loading}>
              {" "}
              Log In{" "}
            </ButtonComponent>
          </form>

          {/* or area */}
          <div className="text-center">
            <div className="border-t border-1"></div>
            <span className="text-xs font-medium bg-white p-2 px-5 relative top-[-13px]">
              Or social media login
            </span>
          </div>

          {/* social-media login */}
          <div className="flex gap-4">
            <button className="bg-red-700 hover:bg-red-600 flex gap-2 shadow-lg rounded-md border border-2 px-4 py-3 text-white text-xs w-1/2 hover:shadow-xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                height="1.3em"
                viewBox="0 0 488 512"
              >
                <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
              </svg>
              <span>Continue with Google</span>
            </button>

            <button className="bg-blue-900 hover:bg-blue-800 flex gap-2 shadow-lg rounded-md border border-2 px-4 py-3 text-white text-xs w-1/2 hover:shadow-xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                height="1.3em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
              <span>Continue with Facebook</span>
            </button>
          </div>

          {/* extra links */}
          <div>
            <div className="text-center font-light mb-10 text-sm">
              <p className="font-[400]">
                Don't have an account?
                <Link
                  to="/auth/register"
                  className="font-semibold ml-1 text-[#001944]"
                >
                  Register
                </Link>
              </p>

              <p className="mt-2">
                <Link
                  to="/auth/reset-password"
                  className=" text-md text-center font-semibold text-[#001944]"
                >
                  Forgot Password?
                </Link>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <HasLoggedIn />
      )}
    </AuthLayout>
  );
};

export default MembersLogin;
