import { LayoutProps } from "../app-schemas/common";
import { isSubdomain } from "../util";
import ChurchMemberAside from "./common/ChurchMemberAuthAside";
import ChurchRegAside from "./common/ChurchRegAside";
import Clients from "./common/Clients";
import Copyright from "./common/Copyright";
import GlobalLayout from "./common/GlobalLayout";

const checkDomain = isSubdomain();
const AuthLayout = ({ children, title = "GenesisCHMS" }: LayoutProps) => (
  <GlobalLayout title={title}>
    <h1 className="w-12/12 lg:hidden flex font-medium justify-center bg-gray-100 p-4">GENESISCHMS</h1>
    <div className="flex justify-center items-center 2xl:py-12 auth-bg-1">
      <div className="2xl:w-9/12 w-full">
        <div className="flex 2xl:shadow-2xl">
          {/* import as component tetant-app ?? tetant-members  */}
          <div className="w-6/12 hidden lg:flex py-12 2xl:rounded-l-lg bg-gray-100 flex justify-center">
            {checkDomain ? <ChurchMemberAside /> : <ChurchRegAside />}
          </div>

          {/* main chid content */}
          <div className="w-full lg:w-6/12 py-12 2xl:rounded-r-lg bg-white flex justify-center">
            {children}
          </div>
        </div>

        {!checkDomain && <Clients />}

        <Copyright />
      </div>
    </div>
  </GlobalLayout>
);

export default AuthLayout;
