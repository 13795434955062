//! define all the app routes here
import { Routes, Route } from "react-router-dom";

// Import admin view
import PeopleList from "../../views/church-people/PeopleList";

function AdminRoutes() {
  return (
    <Routes>
      <Route path="people" element={<PeopleList />} />
      <Route path="people/:filterBy" element={<PeopleList />} />
    </Routes>
  );
}

export default AdminRoutes;
