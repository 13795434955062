import React, { useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import configureStore from "../store";
import { toast } from "react-toastify";
import { SingedInUser } from "../app-schemas/signed_in_user.interface";
import { HeadProps } from "../app-schemas/common";
import { MembersLoginResponse } from "../app-schemas/church_users_authentication.interface";

// document-title
export const Head: React.FC<HeadProps> = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
};

// Function to check if a URL is a subdomain
export const isSubdomain = () => {
  try {
    const url = window.location.href;

    const hostname = new URL(url).hostname; // Extract the hostname from the URL
    const parts = hostname.split("."); // Split the hostname by dot (.)

    if (hostname.match("localhost")) {
      return parts.length > 1; // For localhost, consider any additional parts as subdomains
    } else {
      return parts.length > 2; // For other domains, consider at least two parts as subdomains
    }
  } catch (error) {
    return false; // Return false for invalid URLs
  }
};

// small pushpin alert
export function tinyAlert(type: string, message: string = "") {
  toast.dismiss();
  switch (type) {
    case "info":
      toast.info(message, {
        autoClose: 30000,
      });
      break;
    case "success":
      toast.success(message, {
        autoClose: 60000,
      });
      break;
    case "warning":
      toast.warning(message, {
        autoClose: 30000,
      });
      break;
    case "error":
      toast.error(message, {
        autoClose: 30000,
      });
      break;
    case "close":
      toast.dismiss();
      break;
    default:
      toast.error(message, {
        autoClose: 30000,
      });
  }
}

// return todays date YYYY-MM-DD
export const getCurrentDateFormatted = () => {
  const date = new Date();

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

// Function to store JWT token in session storage
export const storeToken = (token: string) => {
  sessionStorage.setItem("aa_public", token);
};

// Function to get JWT token from session storage
export const getToken = () => {
  return sessionStorage.getItem("aa_public");
};

// Function to remove JWT token from session storage
export const removeToken = () => {
  sessionStorage.removeItem("aa_public");
};
export const destroySession = () => {
  sessionStorage.clear();
  window.location.reload();
};

// Function to store USER in session storage
export const storeUser = (data:MembersLoginResponse) => {
  const json_data = JSON.stringify(data)
  sessionStorage.setItem("aa_data", json_data);
};

// Function to get USER from session storage
export const getUser = () => {
  const json_data:any = sessionStorage.getItem("aa_data");
  return JSON.parse(json_data);
};

// Function to remove USER from session storage
export const removeUser = () => {
  sessionStorage.removeItem("aa_data");
};


















































// helpers const && types/interfaces
type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";
type ResponseType =
  | "arraybuffer"
  | "blob"
  | "document"
  | "json"
  | "text"
  | "stream";
const MySwal = withReactContent(Swal);
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

//  page preloader
export function preloader() {
  MySwal.fire({
    title: "",
    html:
      '<div style="font-size:12px"><img style="width: 50px; display:inline" src="' +
      process.env.PUBLIC_URL +
      '/loading-icon-red.gif" /><br> <strong> Please Wait</strong> </div>',
    showConfirmButton: false,
    showCancelButton: false,
    allowOutsideClick: false,
  });
}

// get bearer token from store
export function getAccessToken() {
  const state = configureStore.getState();

  if (state.singedInUser.value != null) {
    const signedInUser: SingedInUser = state.singedInUser.value;
    return signedInUser.token.access;
  } else return null;
}

// AJAX Caller
export async function _$http(
  method: HttpMethod,
  endpoint: string,
  data: Record<string, any> = {},
  isPaginate: boolean = false,
  responseType: ResponseType = "json",
  requireToken: boolean = true
): Promise<AxiosResponse> {
  let accessToken = getAccessToken();

  return axios({
    method: method,
    url: isPaginate ? endpoint : SERVER_URL + endpoint,
    data: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken && `Bearer ${accessToken}`,
    },
    responseType: responseType,
  });
}

// error alert;
export function errorAlert(message: string) {
  MySwal.fire({
    icon: "error",
    title: "",
    timer: 80000,
    timerProgressBar: true,
    html: `<small>${message}</small>`,
    footer: '<a href="#!" style="font-size:12px">Why do I have this issue?</a>',
    showDenyButton: true,
    denyButtonText: `<small>Ok</small>`,
    showConfirmButton: false,
  });
}

// function to return axios error;
export function axiosError(error: any) {
  let errorMessage = "Error Occured ";
  if (error?.response?.status) {
    if (error.response.status === 401) {
      errorAlert(
        "Your session has expired, you will be redirected to login page"
      );
      // window.location.href = ('/login');
      return;
    }

    if (error.response?.data == null) {
      errorMessage = "Error Occured ";
    } else {
      let errorObject = error.response.data;
      if (error.response.data.error != null) {
        errorMessage = error.response.data.error;
      }

      if (error.response.data.message != null) {
        errorMessage = error.response.data.message;
      } else {
        for (const key in errorObject) {
          if (Array.isArray(errorObject[key]) && errorObject[key].length > 0) {
            errorMessage = errorObject[key][0];
            break;
          }
        }
      }
    }
  } else {
    errorMessage = error.message;
  }

  console.log(error);
  errorAlert(errorMessage);
}

// close MySwal;
export function closeAlert() {
  MySwal.close();
}

// password rules
export function passwordRules(password: string) {
  if (!password.match(/^(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,}$/)) {
    return true;
  }
  //(?=.*[A-Z]);
}

export function PasswordReveal(id: string, show: string, hide: string) {
  let input: HTMLInputElement | null = document.querySelector("#" + id);
  let btnHide: HTMLElement | null = document.querySelector("#" + hide);
  let btnShow: HTMLInputElement | null = document.querySelector("#" + show);

  if (input !== null) {
    if (input.type === "password") input.type = "text";
    else input.type = "password";
  }

  if (btnShow !== null) btnShow.style.display = "block";

  if (btnHide !== null) btnHide.style.display = "none";
}
export function PerPageNumber(urlString: string | null) {
  if (urlString) {
    try {
      const url = new URL(urlString);
      const pageValue = Number(url.searchParams.get("page"));

      if (pageValue > 1) {
        return (pageValue - 1) * 10 + 1;
      } else return 1;
    } catch (error) {
      return 1;
    }
  } else {
    return 1;
  }
}
