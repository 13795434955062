import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getToken, removeToken } from "../../util";

const MembersLandingPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    setIsLoggedIn(!!getToken());
  }, []);

  const logout = () => {
    removeToken();
    setIsLoggedIn(false);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-gray-600 text-white py-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-lg font-medium">
            Living Impact Christian Centre
          </h1>
          <nav>
            {!isLoggedIn ? (
              <>
                <Link to="/auth/login" className="px-4">
                  Login
                </Link>
                <Link to="/auth/register" className="px-4">
                  Sign Up
                </Link>
              </>
            ) : (
              <>
                <button
                  onClick={() => logout()}
                  className="px-4 bg-red-600 rounded p-2 font-medium text-sm"
                >
                  Logout
                </button>
              </>
            )}
          </nav>
        </div>
      </header>

      <main className="flex-grow hidden">
        <section className="bg-gray-100 text-center py-20">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold mb-4">Welcome to Our Church</h2>
            <p className="text-xl mb-8">
              Join us in worship and fellowship as we grow together in faith.
            </p>
            <Link
              to="#about"
              className="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-700 transition duration-300"
            >
              Learn More
            </Link>
          </div>
        </section>

        <section id="about" className="container mx-auto py-20 hidden">
          <h2 className="text-3xl font-bold mb-4">About Us</h2>
          <p className="text-lg mb-8">
            Our church is a community of believers who come together to worship,
            learn, and grow in our faith. We are committed to serving God and
            spreading His love.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white p-6 shadow-md rounded-lg">
              <h3 className="text-2xl font-bold mb-2">Our Mission</h3>
              <p>
                To share the love of Jesus Christ and to make disciples of all
                nations, baptizing them in the name of the Father, Son, and Holy
                Spirit.
              </p>
            </div>
            <div className="bg-white p-6 shadow-md rounded-lg">
              <h3 className="text-2xl font-bold mb-2">Our Vision</h3>
              <p>
                To be a light in our community, providing hope, love, and
                support to all who seek it.
              </p>
            </div>
          </div>
        </section>

        <section id="services" className="bg-gray-100 py-20 hidden">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4">Services</h2>
            <p className="text-lg mb-8">
              Join us for our weekly services and special events.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white p-6 shadow-md rounded-lg">
                <h3 className="text-2xl font-bold mb-2">Sunday Worship</h3>
                <p>10:00 AM - 11:30 AM</p>
              </div>
              <div className="bg-white p-6 shadow-md rounded-lg">
                <h3 className="text-2xl font-bold mb-2">Bible Study</h3>
                <p>Wednesday 7:00 PM - 8:30 PM</p>
              </div>
              <div className="bg-white p-6 shadow-md rounded-lg">
                <h3 className="text-2xl font-bold mb-2">Youth Group</h3>
                <p>Friday 6:00 PM - 7:30 PM</p>
              </div>
            </div>
          </div>
        </section>

        <section
          id="contact"
          className="container mx-auto py-20 text-center hidden"
        >
          <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
          <p className="text-lg mb-8">
            We would love to hear from you! Reach out to us with any questions
            or prayer requests.
          </p>
          <form className="max-w-lg mx-auto">
            <div className="mb-4">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Your Email"
                className="w-full p-3 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="mb-4">
              <textarea
                placeholder="Your Message"
                className="w-full p-3 border border-gray-300 rounded-lg"
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-700 transition duration-300"
            >
              Send Message
            </button>
          </form>
        </section>
      </main>

      <footer className="bg-gray-600 text-white py-4 text-center hidden">
        <p>&copy; 2024 Our Church. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default MembersLandingPage;
