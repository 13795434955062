//! define all the app routes here
import {Routes, Route } from "react-router-dom";

// Import views for tenant-auth-routes
import MembersLoginUser from "../../views/church-users-authentication/MembersLogin";
import MembersSignUp from "../../views/church-users-authentication/MembersSignUp";
import TwoFA from "../../views/church-users-authentication/TwoFA";

function AuthRoutes() {
  return (
    <Routes>
      <Route path="login" element={<MembersLoginUser />} />
      <Route path="register" element={<MembersSignUp />} />
      <Route path="auth-2fa" element={<TwoFA />} />
      
      <Route path="request-reset" element={<TwoFA />} />
      <Route path="reset-password" element={<TwoFA />} />
    </Routes>
  );
}

export default AuthRoutes;
