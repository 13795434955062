import React, { useState } from "react";
import { ErrorMessage } from "./ErrorMessage";

type Option = {
  value: string | number;
  label: string;
};

type SelectDropdownProps = {
  id: string;
  className?: string;
  placeholder?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  options: Option[];
  label?: string;
  icon?: React.ComponentType;
  error?: string;
};

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  id,
  className = "",
  placeholder,
  value,
  onChange,
  required = false,
  options,
  label,
  icon: Icon,
  error,
}) => {
  const [isFocused, setIsFocused] = useState(true);

  return (
    <div className={`relative ${className}`}>
      {Icon && (
        <span className="absolute top-4 left-3 text-[#98A1B0]">
          <Icon />
        </span>
      )}
      <select
        id={id}
        className={`h-[50px] focus:outline-none focus:shadow-outline border border-input border-gray-400 rounded-md 
        pl-${Icon ? 9 : 4} 
        ${error && "border-red-700"} 
        py-3 w-full text-md text-gray-700 focus:shadow-lg focus:border-[#58595c] ${className}`}
        value={value}
        onChange={onChange}
        onFocus={onChange}
        onBlur={() => setIsFocused(true)}
        // required={required}
      >
        {placeholder && !value && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {label && isFocused && (
        <label
          htmlFor={id}
          className="absolute top-[-8px] left-3 text-xs text-gray-600 bg-white px-1"
        >
          {required && <span className="text-red-600">* </span>}
          {label}
        </label>
      )}

      {error && <ErrorMessage message={error} />}
    </div>
  );
};

export default SelectDropdown;
