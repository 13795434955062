import { z } from "zod";

// Define the forbidden keywords
const forbiddenKeywords = ["password", "123456", "admin", "qwerty", "letmein", "etc"];
const passwordSchema = z
  .string()
  .min(6, "Password must be at least 6 characters long")
  .regex(/(?=.*[a-z])/, "Password must contain at least one lowercase letter")
  .regex(/(?=.*[A-Z])/, "Password must contain at least one uppercase letter")
  .regex(/(?=.*\d)/, "Password must contain at least one number")
  .regex(
    /(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).*$/,
    "Password must contain at least one special character"
  )
  .refine(
    (value) =>
      !forbiddenKeywords.some((keyword) =>
        value.toLowerCase().includes(keyword)
      ),
    {
      message: `Password cannot contain common phrases like: ${forbiddenKeywords.join(
        ", "
      )}`,
    }
  );

//[page 1] CreateAdminAccountFormType
export type CreateAdminAccountFormType = {
  fullName: string;
  email: string;
  phone: string;
  accountType: string;
  creatorRole: string;
  password: string;
  confirmPassword: string;
};
export const CreateAdminAccountFormDefault: CreateAdminAccountFormType = {
  fullName: "",
  email: "",
  phone: "",
  accountType: "",
  creatorRole: "",
  password: "",
  confirmPassword: "",
};
export const CreateAdminAccountFormSchema = z
  .object({
    fullName: z.string().min(5, "Full name is required"),
    email: z.string().email("Valid email address is required"),
    phone: z.string().min(6, "Phone number is required"),
    accountType: z.string().min(1, "Account type is required"),
    creatorRole: z.string().min(1, "Role is required"),
    password: passwordSchema,
    confirmPassword: z
      .string()
      .min(6, "Confirm Password must be at least 6 characters long"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords & confirm password must match",
    path: ["confirmPassword"], // This specifies which field will get the error message
  });

// ---
// [page 2] AddChurchInfoFormType
export type AddChurchInfoFormType = {
  churchName: string;
  shortLink: string;
  totalMembers: string;
  website: string;
  churchAddress: string;
  churchCity: string;
  churchCountry: string;
};
export const AddChurchInfoFormDefault: AddChurchInfoFormType = {
  churchName: "",
  shortLink: "",
  totalMembers: "",
  website: "",
  churchAddress: "",
  churchCity: "",
  churchCountry: "",
};
export const AddChurchInfoFormSchema = z.object({
  churchName: z.string().min(3, "Church name is required"),
  shortLink: z.string().min(2, "Minimum of two letters"),
  totalMembers: z.string().min(1, "total Members is required"),
  website: z.string().url("Invalid website address"),
  churchAddress: z.string().min(3, "Church name is required"),
  churchCity: z.string().min(6, "Enter city"),
  churchCountry: z.string().min(1, "Choose country"),
});
