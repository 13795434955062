import React from "react";
import { LuLoader } from "react-icons/lu";

type ButtonComponentProps = {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
  loading?: boolean;
};

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  type = "button",
  disabled = false,
  className = "",
  onClick,
  children,
  loading,
}) => {
  return (
    <button
      type={type}
      className={`inline-flex items-center justify-center whitespace-nowrap font-bold text-md py-3 px-4 mb-4 bg-orange-500 text-white w-full rounded-md col-span-full shadow-lg font-medium hover:shadow-xl disabled:bg-gray-300 disabled:text-gray-400 disabled:shadow-sm ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? <LuLoader className="text-2xl animate-spin" /> : children}
    </button>
  );
};

export default ButtonComponent;
