import { FormEvent, useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import usePreventDefault from "../../hooks/PreventDefault";
import ProgressStepper from "./common/ProgressStepper";
import InputField from "../../components/InputField";
import ButtonComponent from "../../components/ButtonComponent";
import { Link } from "react-router-dom";
import { PadLock, UserEdit } from "../../components/Icons";
import { LuMail, LuPhone, LuUserCheck, LuUserCog } from "react-icons/lu";
import SelectDropdown from "../../components/SelectDropdown";
import { account_type_options, role_options } from "../../store/data";
import {
  CreateAdminAccountFormDefault,
  CreateAdminAccountFormSchema,
  CreateAdminAccountFormType,
} from "../../app-schemas/new_church_registration.interface";
import useFormInput from "../../hooks/useFormInput";
import church from "../../api-services/new_church_registration.services";
import { tinyAlert } from "../../util";

const CreateAdminAccount = () => {
  const __PDef = usePreventDefault();
  const [loading, setLoading] = useState(false);

  const [
    formData,
    handleChange,
    errors,
    setValidationErrors,
    handleCustomChange,
    resetForm,
  ] = useFormInput(CreateAdminAccountFormDefault, CreateAdminAccountFormSchema);

  // create admin account
  const handleCreateAdminAccount = async (e: FormEvent) => {
    // prevent default actions
    __PDef(e);

    // use zod to validate form schema
    const validations = CreateAdminAccountFormSchema.safeParse(formData);

    if (validations.success) {
      setLoading(true);
      const response = await church.CreateAdminAccount(validations.data);
      const uuid = response?.data?.uuid;

      if (response && uuid) {
        tinyAlert(
          "success",
          `Your admin account was created successfuly, A link to step two is sent to your email address: ${formData.email}`
        );
        resetForm();
      }

      setLoading(false);
    } else {
      const newErrors: Partial<
        Record<keyof CreateAdminAccountFormType, string>
      > = {};

      validations.error.errors.forEach((error) => {
        newErrors[error.path[0] as keyof CreateAdminAccountFormType] =
          error.message;
      });

      setValidationErrors(newErrors);
    }
  };

  return (
    <AuthLayout title="GenesisCHMS | Create my free church admin account">
      <div className="w-11/12 lg:w-9/12 flex flex-col gap-5">
        {/* S1: hero-text area */}
        <div className="flex flex-col items-center gap-2.5 p-3">
          <h4 className="text-[2rem] lg:text-[3rem] font-[800] text-[#181e35]">
            Sign Up
          </h4>
          <p className="text-xs lg:text-md not-italic font-[500] lg:leading-6 text-[#181e35]">
            Answer a few questions to get started
          </p>
          <p className="text-xs hidden">
            Complete all required (<span className="text-red-600">*</span>)
            fields
          </p>
        </div>

        {/* S2: form-progress-stepper area */}
        <ProgressStepper currentStep={0} />

        {/* S3: complete form area to signup church */}
        <form
          onSubmit={handleCreateAdminAccount}
          className="grid grid-cols-2 gap-5 mt-5"
        >
          <InputField
            className="col-span-full"
            type="text"
            id="fullName"
            placeholder="What is your Full Name"
            label="Full Name"
            value={formData.fullName}
            onChange={handleChange}
            required
            icon={UserEdit}
            error={errors?.fullName}
          />

          <InputField
            className="col-span-full sm:col-span-1"
            type="email"
            id="email"
            placeholder="Enter Email Address"
            label="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
            icon={LuMail}
            error={errors?.email}
          />

          <InputField
            className="col-span-full sm:col-span-1"
            type="tel"
            id="phone"
            placeholder="Enter Phone Number"
            label="Phone"
            value={formData.phone}
            onChange={(e) => handleCustomChange("phone", e.target.value)}
            required
            icon={LuPhone}
            error={errors?.phone}
          />

          <SelectDropdown
            className="col-span-full sm:col-span-1"
            id="accountType"
            value={formData.accountType}
            onChange={handleChange}
            options={account_type_options}
            placeholder="Account Type"
            label="Account Type"
            required
            icon={LuUserCheck}
            error={errors?.accountType}
          />

          <SelectDropdown
            className="col-span-full sm:col-span-1"
            id="creatorRole"
            value={formData.creatorRole}
            onChange={handleChange}
            options={role_options}
            placeholder="Role"
            label="Role"
            required
            icon={LuUserCog}
            error={errors?.creatorRole}
          />

          <InputField
            className="col-span-full sm:col-span-1"
            type="password"
            id="password"
            placeholder="* * * * * * * *"
            label="password"
            value={formData.password}
            onChange={handleChange}
            required
            icon={PadLock}
            error={errors?.password}
          />

          <InputField
            className="col-span-full sm:col-span-1"
            type="password"
            id="confirmPassword"
            placeholder="* * * * * * * *"
            label="Confirm password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            icon={PadLock}
            error={errors?.confirmPassword}
          />

          <ButtonComponent type="submit" disabled={loading} loading={loading}>
            Create Account
          </ButtonComponent>
        </form>

        {/* S4 aggrement area with sign-in link */}
        <div>
          <p className="text-[10px] text-center text-gray-500 mb-3">
            By signing up, you're agreeing to our{" "}
            <Link to="/privacy-policy" className="underline font-semibold">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link to="/terms-of-services" className="underline font-semibold">
              Terms of Service
            </Link>
          </p>

          <p className="text-center text-sm font-normal font-light mb-10 hidden">
            Already have an account?
            <Link to="/" className="font-medium ml-1 text-[#001944]">
              Continue here
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default CreateAdminAccount;
