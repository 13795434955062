const LoadingScreen = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <div className="flex justify-center ">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-24 w-24 mb-4"></div>
        </div>
        <h2 className="text-center text-gray-700 text-2xl font-semibold text-sm mt-5">
          Loading...
        </h2>
        <p className="text-gray-600 font-medium text-sm mt-3">
          Please wait while we load the content for you.
        </p>

        <p className="mt-10 font-semibold">&copy;2024 Genesis CHMS</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
