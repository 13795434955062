import React from 'react';
import { Link } from 'react-router-dom';

interface NavItemProps {
  to: string;
  activePath: string;
  paths: string[];
  Icon: React.ComponentType;
  label: string;
}

const NavItem: React.FC<NavItemProps> = ({ to, activePath, paths, Icon, label }) => {
  const isActive = paths.includes(activePath);
  const activeClass = isActive ? "bg-gray-50 border-l-[3px] rounded-sm shadow-sm border-l-orange-500 text-orange-600" : "";

  return (
    <li className={`${activeClass} mb-2`}>
      <Link to={to} className="block py-2.5 pl-4 hover:bg-gray-100 flex rounded text-[#433939]">
        <Icon />
        <label className="ml-4 font-medium">{label}</label>
      </Link>
    </li>
  );
};

export default NavItem;
